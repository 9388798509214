import { environment } from "../../environments/environment";

export interface Category {
    category_id: number,
    name: string
};

export const getCategories = async (params?: any) => {
    let response:any = await fetch(environment.apiUrl+'/api/categories', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    });

    response = await response.json();

    return response.data as Category[];
}