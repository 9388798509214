import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { encrypt } from "../../infrastructure/utils/encrypt"
import { LoginResponse, User, UserKey, onLogin, onSignIn } from "../../infrastructure/services/user"
import { parseJwt } from "../../infrastructure/utils/JWTParser"
import './style.sass'

function SignUp() {
  const [form, updateForm] = useState<User>({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    usertype: '',
    password: '',
    password_confirmation: ''
  })
  const [formErrors, updateFormErrors] = useState<Record<string, string[]>>({})
  const [loading, updateLoading] = useState(false)
  const navigator = useNavigate()

  const validateForm = () => {
    let errors: Record<string, string[]> = {};
    Object.keys(form).map((key) => {
      if(form[key as UserKey] == '') {
        errors[key] = ['Campo obrigatório'];
      };
      if(key === 'password_confirmation') {
        if(form[key] !== form['password']) {
          errors[key] = ['Senhas precisam ser iguais'];
        };
      };
    });
    updateFormErrors(errors);

    return Object.values(errors).filter(x => x.length > 0).length === 0
  }

  const onSubmit = async () => {
    if(loading) return;
    try {
      if(!validateForm()) return;

      updateLoading(true);

      const form_data = {
        ...form,
        password: encrypt(form.password),
        password_confirmation: encrypt(form.password_confirmation as string)
      }
  
      await onSignIn(form_data);
      const response = await onLogin(form_data) as LoginResponse;

      const result = parseJwt(response.auth_token);
      localStorage.setItem('auth_token', response.auth_token);
      localStorage.setItem('user_id', result.user.user_id);

      updateLoading(false);
      navigator('/');
    } catch (error) {
      updateLoading(false);
    }
  }

  return (
    <div className='sign-up'>
      <div className="form-container">
        <div className="form-item">
          <input type="text" name="email" placeholder="email" onChange={e => updateForm(prevState => ({ ...prevState, email: e.target.value }))} />
          {formErrors.email && formErrors.email.length > 0 && 
          <div className="errors">
            {formErrors.email && formErrors.email.map((error, index) => 
            <p key={'error_email_'+index}>{error}</p>)}
          </div>}
        </div>
        <div className="form-item">
          <input type="text" name="first_name" placeholder="first_name" onChange={e => updateForm(prevState => ({ ...prevState, first_name: e.target.value }))} />
          {formErrors.first_name && formErrors.first_name.length > 0 && 
          <div className="errors">
            {formErrors.first_name && formErrors.first_name.map((error, index) => 
            <p key={'error_first_name_'+index}>{error}</p>)}
          </div>}
        </div>
        <div className="form-item">
          <input type="text" name="last_name" placeholder="last_name" onChange={e => updateForm(prevState => ({ ...prevState, last_name: e.target.value }))} />
          {formErrors.last_name && formErrors.last_name.length > 0 && 
          <div className="errors">
            {formErrors.last_name && formErrors.last_name.map((error, index) => 
            <p key={'error_last_name_'+index}>{error}</p>)}
          </div>}
        </div>
        <div className="form-item">
          <input type="text" name="phone" placeholder="phone" onChange={e => updateForm(prevState => ({ ...prevState, phone: e.target.value }))} />
          {formErrors.phone && formErrors.phone.length > 0 && 
          <div className="errors">
            {formErrors.phone && formErrors.phone.map((error, index) => 
            <p key={'error_phone_'+index}>{error}</p>)}
          </div>}
        </div>
        <div className="form-item">
          <input type="password" name="password" placeholder="password" onChange={e => updateForm(prevState => ({ ...prevState, password: e.target.value }))} />
          {formErrors.password && formErrors.password.length > 0 && 
          <div className="errors">
            {formErrors.password && formErrors.password.map((error, index) => 
            <p key={'error_password_'+index}>{error}</p>)}
          </div>}
        </div>
        <div className="form-item">
          <input type="password" name="password_confirmation" placeholder="password_confirmation" onChange={e => updateForm(prevState => ({ ...prevState, password_confirmation: e.target.value }))} />
          {formErrors.password_confirmation && formErrors.password_confirmation.length > 0 && 
          <div className="errors">
            {formErrors.password_confirmation && formErrors.password_confirmation.map((error, index) => 
            <p key={'error_password_confirmation_'+index}>{error}</p>)}
          </div>}
        </div>
        <button onClick={onSubmit}>{ !loading ? 'Enviar' : 'Enviando...' }</button>
      </div>
    </div>
  )
}

export default SignUp