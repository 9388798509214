import { environment } from "../../environments/environment";

export interface Store {
    store_id: number,
    name: string,
    image: string
}

export const getStores = async () => {
    let response:any = await fetch(environment.apiUrl+'/api/stores', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    });

    response = await response.json();

    return response.data as Store[];
}