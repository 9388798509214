import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { encrypt } from "../../infrastructure/utils/encrypt"
import { LoginData, LoginKey, LoginResponse, onLogin } from "../../infrastructure/services/user"
import { parseJwt } from "../../infrastructure/utils/JWTParser"
import './style.sass'

function Login() {
  const [form, updateForm] = useState<LoginData>({
    email: '',
    password: ''
  })
  const [formErrors, updateFormErrors] = useState<Record<string, string[]>>({})
  const [loading, updateLoading] = useState(false)
  const navigator = useNavigate()

  const validateForm = () => {
    let errors: Record<string, string[]> = {};
    Object.keys(form).map((key) => {
      if(form[key as LoginKey] == '') {
        errors[key] = ['Campo obrigatório'];
      };
    });
    updateFormErrors(errors);

    return Object.values(errors).filter(x => x.length > 0).length === 0
  }

  const onSubmit = async () => {
    if(loading) return;
    try {
      if(!validateForm()) return;

      updateLoading(true);

      const form_data = {
        ...form,
        password: encrypt(form.password)
      };
      const response = await onLogin(form_data) as LoginResponse;

      const result = parseJwt(response.auth_token);
      localStorage.setItem('auth_token', response.auth_token);
      localStorage.setItem('user_id', result.user.user_id);

      updateLoading(false);
      navigator('/');
    } catch (error) {
      updateLoading(false);
    }
  }

  return (
    <div className='sign-up'>
      <div className="form-container">
        <div className="form-item">
          <input type="text" name="email" placeholder="email" onChange={e => updateForm(prevState => ({ ...prevState, email: e.target.value }))} />
          {formErrors.email && formErrors.email.length > 0 && 
          <div className="errors">
            {formErrors.email && formErrors.email.map((error, index) => 
            <p key={'error_email_'+index}>{error}</p>)}
          </div>}
        </div>
        <div className="form-item">
          <input type="password" name="password" placeholder="password" onChange={e => updateForm(prevState => ({ ...prevState, password: e.target.value }))} />
          {formErrors.password && formErrors.password.length > 0 && 
          <div className="errors">
            {formErrors.password && formErrors.password.map((error, index) => 
            <p key={'error_password_'+index}>{error}</p>)}
          </div>}
        </div>
        <button onClick={onSubmit}>{ !loading ? 'Enviar' : 'Enviando...' }</button>
      </div>
    </div>
  )
}

export default Login