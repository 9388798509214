import { useEffect, useRef, useState } from 'react'
import leftIcon from '../../assets/icons/arrow-left.png'
import rightIcon from '../../assets/icons/arrow-right.png'
import ItemBottomSlider from './ItemBottomSlider'

function Slider({ passedSlides }: { passedSlides: any[] }) {
  const sliderRef = useRef(null)
  const [slides, updateSlides] = useState<string[]>(passedSlides)
  const [currentSlide, changeCurrentSlide] = useState(1)
  const [sliderWidth, setSliderWidth] = useState(0)
  const [slidePosition, updateSlidePosition] = useState(0)
  const [sliderInterval, updateSliderInterval] = useState<any>()
  const currentSlideRef = useRef(1)
  const slidePositionRef = useRef(1)

  useEffect(() => {
    currentSlideRef.current = currentSlide;
    slidePositionRef.current = slidePosition;
  }, [currentSlide, slidePositionRef])
  
  useEffect(() => {
    if(slides.length === passedSlides.length) return;
    if(!sliderRef.current) return;

    const sliderWidth = (sliderRef.current as HTMLElement).offsetWidth;
    updateSlidePosition(sliderWidth);

    updateSliderInterval(setInterval(() => {
      changeSlide(currentSlideRef.current + 1);
    }, 5000))
    
  }, [slides, sliderRef])
  

  useEffect(() => {
    if(!sliderRef.current) return;

    const sliderWidth = (sliderRef.current as HTMLElement).offsetWidth;
    setSliderWidth(sliderWidth);

    if(passedSlides.length === 1) {
      return;
    };

    const slidesCopy = [...slides];
    const firstSlide = slidesCopy.shift() as string;
    const lastSlide = slidesCopy.pop() as string;

    updateSlides([
      lastSlide,
      ...slides,
      firstSlide
    ]);
  }, [])
  
  const onBtnClick = (new_slide_position: number) => {
    clearInterval(sliderInterval);
    updateSliderInterval(null);
    changeSlide(new_slide_position);

    setTimeout(() => {
      updateSliderInterval(setInterval(() => {
        changeSlide(currentSlideRef.current + 1);
      }, 3000))
    }, 500);
  };

  const changeSlide = (new_slide_position: number) => {
    if(!sliderRef.current) return;
    if(new_slide_position == currentSlideRef.current) return;
    const slider = document.querySelector('.slides') as HTMLElement;
    const sliderContainer = sliderRef.current as HTMLElement;
    const sliderWidth = (sliderRef.current as HTMLElement).offsetWidth;

    if(new_slide_position > currentSlideRef.current) {
      if(new_slide_position == slides.length-1) {
        slider.style.transition = '0.5s';
        sliderContainer.style.pointerEvents = 'none';
        updateSlidePosition(sliderWidth * (new_slide_position));
        changeCurrentSlide(1);

        setTimeout(() => {
          slider.style.transition = '0s';
          sliderContainer.style.pointerEvents = 'inherit';
          updateSlidePosition(sliderWidth);
        }, 500);
        
        return
      };

      slider.style.transition = '0.5s';
      sliderContainer.style.pointerEvents = 'none';
      updateSlidePosition(sliderWidth * (new_slide_position));
      changeCurrentSlide(new_slide_position);

      setTimeout(() => {
        sliderContainer.style.pointerEvents = 'inherit';
      }, 500);
    };

    if(new_slide_position < currentSlide) {
      if(new_slide_position == 0) {
        slider.style.transition = '0.5s';
        sliderContainer.style.pointerEvents = 'none';
        updateSlidePosition(0);
        changeCurrentSlide(passedSlides.length);

        setTimeout(() => {
          slider.style.transition = '0s';
          sliderContainer.style.pointerEvents = 'inherit';
          updateSlidePosition(sliderWidth * (passedSlides.length));
        }, 500);
        
        return
      };

      slider.style.transition = '0.5s';
      sliderContainer.style.pointerEvents = 'none';
      updateSlidePosition(sliderWidth * (new_slide_position));
      changeCurrentSlide(new_slide_position);

      setTimeout(() => {
        sliderContainer.style.pointerEvents = 'inherit';
      }, 500);
    };
  }

  return (
    <div className='double-slider-container'>
      <div ref={sliderRef} className='item-slider-container noselect'>
        {passedSlides.length > 1 &&
        <>
          <button className='previous jump-btn' onClick={() => onBtnClick(currentSlide-1)}>
            <img src={leftIcon} />
          </button>
          <button className='next jump-btn' onClick={() => onBtnClick(currentSlide+1)}>
            <img src={rightIcon} />
          </button>
        </>}
        <div className='slides' style={{marginLeft: -slidePosition+'px'}}>
          {
            passedSlides.map((slide, index) => 
              <div key={'item_slide_'+index} className='slide' style={{width: sliderWidth}}>
                <img className='background' src={slide} />
              </div>  
            )
          }
        </div>
        {passedSlides.length > 1 &&
        <div className='slide-dots'>
          {
            Array.from(Array(passedSlides.length).keys()).map(i => 
              <div key={'item_dot_'+i} className={'dot '+ (currentSlide === i+1 ? 'selected' : '')} onClick={() => onBtnClick(i+1)}></div>
            )
          }
        </div>}
      </div>
      {passedSlides.length > 1 &&
      <ItemBottomSlider
      slides={passedSlides}
      currentSlide={currentSlide}
      onJumpSlide={index => onBtnClick(index)}/>}
    </div>
  )
}

export default Slider