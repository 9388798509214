function Bars() {
  return (
    <div className='bars'>
        <div className='bar'>
            <div className='bar-content'>
              <p>AS MELHORES OFERTAS</p> <span></span> <p>ATUALIZADO DIARIAMENTE</p> <span></span> <p>SELECIONADO CUIDADOSAMENTE</p>
              <span></span>
              <p>AS MELHORES OFERTAS</p> <span></span> <p>ATUALIZADO DIARIAMENTE</p> <span></span> <p>SELECIONADO CUIDADOSAMENTE</p>
              <span></span>
              <p>AS MELHORES OFERTAS</p> <span></span> <p>ATUALIZADO DIARIAMENTE</p> <span></span> <p>SELECIONADO CUIDADOSAMENTE</p>
              <span></span>
              <p>AS MELHORES OFERTAS</p> <span></span> <p>ATUALIZADO DIARIAMENTE</p> <span></span> <p>SELECIONADO CUIDADOSAMENTE</p>
            </div>
        </div>
        <div className='bar'>
            <div className='bar-content'>
              <p>AS MELHORES OFERTAS</p> <span></span> <p>ATUALIZADO DIARIAMENTE</p> <span></span> <p>SELECIONADO CUIDADOSAMENTE</p>
              <span></span>
              <p>AS MELHORES OFERTAS</p> <span></span> <p>ATUALIZADO DIARIAMENTE</p> <span></span> <p>SELECIONADO CUIDADOSAMENTE</p>
              <span></span>
              <p>AS MELHORES OFERTAS</p> <span></span> <p>ATUALIZADO DIARIAMENTE</p> <span></span> <p>SELECIONADO CUIDADOSAMENTE</p>
              <span></span>
              <p>AS MELHORES OFERTAS</p> <span></span> <p>ATUALIZADO DIARIAMENTE</p> <span></span> <p>SELECIONADO CUIDADOSAMENTE</p>
            </div>
        </div>
    </div>
  )
}

export default Bars