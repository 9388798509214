import { environment } from "../../environments/environment";

export interface Brand {
    brand_id: number,
    name: string,
    image: string,
    large_image: string
}

export const getBrands = async () => {
    let response:any = await fetch(environment.apiUrl+'/api/brands', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    });

    response = await response.json();

    return response.data as Brand[];
}