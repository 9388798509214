import { Brand } from "./brand"
import { Store } from "./store"
import mercadoLivreLogo2 from '../../assets/images/stores/mercado-livre-2.png'
import nintendoLargeLogo from '../../assets/images/brands/nintendo-lg-logo.png'
import { environment } from "../../environments/environment"

export interface Product {
    product_id: number,
    name: string,
    description: string,
    image: string,
    brand_id: number,
    category_id: number,
    extra_images: string[],
    created_at: string,
    brand?: Brand
}

export interface Item {
    catalog_item_id: number;
    name: string;
    description: string;
    price?: number;
    discount_price?: number;
    discount_percentage?: string;
    store_id: number;
    products: any[];
    links: any[];
    coupon_links: any[];
    coupons: string[];
    extra_info?: string;
    warning?: string;
    image?: string;
    social_media_image?: string;
    created_at?: string;
    update_at?: string;
    deleted_at?: string;
    users_other_favorites: Item[];
    related_items: Item[];
    store?: Store
}

export interface ListResponse<T> {
  data: T[],
  count: number
}

// export const catalogItemMock: Item = {
//     catalog_item_id: 1,
//     name: 'The Legend of Zelda: Tears of the Kingdom',
//     description: `<p>- Mídia: Física (disco ou cartucho no caso se for Nintendo);- Console: Compatível com Nintendo SWITCH, LITE E OLED;- Versão: Importada;- Legenda ou Texto: Inglês, Francês, Espanhol, Português;- Jogadores: 1 a 4;CONTEÚDO DA EMBALAGEM:1 (um) Jogo SUPER MARIO BROS WONDER SWITCH;IMAGENS MERAMENTE ILUSTRATIVASTodas as informações divulgadas são de responsabilidade do Fabricante/FornecedorSOBRE O JOGOEncante-se com a evolução fenomenal da diversão com o MarioA jogabilidade clássica de deslocamento lateral dos jogos do Mario virou de cabeça para baixo com a chegada das flores fenomenais. Este item revolucionário ativa momentos tão espetaculares que é preciso ver para crer... Observe canos ganharem vida, use sua força devastadora como uma gigantesca bola com espinhos e até se surpreenda com situações inesperados - os eventos fenomenais.Escolha personagens heroicos de Super Mario™ e itens potenciadoresEncare esta aventura como personagens conhecidos da série, como Mario, Luigi, Peach, Daisy, Yoshi e Toad. Até aí, nada de novo, mas… dessa vez você pode usar um item potenciador surpreendente para se transformar em um elefante! Use sua tromba para golpear inimigos!Compartilhe este fenômeno com seus amigos e outros fãs do MarioJunte-se a até três amigos localmente* em um console Nintendo Switch™ e experimentem juntos os revolucionários eventos fenomenais, colaborando para chegar no objetivo final!*Acessórios adicionais, vendidos separadamente, podem ser necessários para o modo multijogador.</p>`,
//     product_id: 1,
//     store_id: 1,
//     coupons: ['2323'],
//     link: 'https://www.youtube.com/watch?v=LzurHPogg1Y',
//     extra_info: `<div><p>Preço já incluindo 30% de cashback</p></div>`,
//     store: {
//       store_id: 1,
//       name: 'Mercado Livre',
//       image: mercadoLivreLogo2
//     },
//     related_items: [],
//     users_other_favorites: [],
//     product: {
//         product_id: 1,
//         image: 'https://assets-prd.ignimgs.com/2022/09/15/artwork-01-1663228486816.jpg',
//         name: 'The Legend of Zelda: Tears of the Kingdom',
//         description: `<p>- Mídia: Física (disco ou cartucho no caso se for Nintendo);- Console: Compatível com Nintendo SWITCH, LITE E OLED;- Versão: Importada;- Legenda ou Texto: Inglês, Francês, Espanhol, Português;- Jogadores: 1 a 4;CONTEÚDO DA EMBALAGEM:1 (um) Jogo SUPER MARIO BROS WONDER SWITCH;IMAGENS MERAMENTE ILUSTRATIVASTodas as informações divulgadas são de responsabilidade do Fabricante/FornecedorSOBRE O JOGOEncante-se com a evolução fenomenal da diversão com o MarioA jogabilidade clássica de deslocamento lateral dos jogos do Mario virou de cabeça para baixo com a chegada das flores fenomenais. Este item revolucionário ativa momentos tão espetaculares que é preciso ver para crer... Observe canos ganharem vida, use sua força devastadora como uma gigantesca bola com espinhos e até se surpreenda com situações inesperados - os eventos fenomenais.Escolha personagens heroicos de Super Mario™ e itens potenciadoresEncare esta aventura como personagens conhecidos da série, como Mario, Luigi, Peach, Daisy, Yoshi e Toad. Até aí, nada de novo, mas… dessa vez você pode usar um item potenciador surpreendente para se transformar em um elefante! Use sua tromba para golpear inimigos!Compartilhe este fenômeno com seus amigos e outros fãs do MarioJunte-se a até três amigos localmente* em um console Nintendo Switch™ e experimentem juntos os revolucionários eventos fenomenais, colaborando para chegar no objetivo final!*Acessórios adicionais, vendidos separadamente, podem ser necessários para o modo multijogador.</p>`,
//         category_id: 1,
//         brand_id: 1,
//         extra_images: [
//             'https://assets-prd.ignimgs.com/2022/09/15/artwork-01-1663228486816.jpg',
//             'https://assets-prd.ignimgs.com/2022/09/15/artwork-01-1663228486816.jpg',
//             'https://assets-prd.ignimgs.com/2022/09/15/artwork-01-1663228486816.jpg',
//             'https://assets-prd.ignimgs.com/2022/09/15/artwork-01-1663228486816.jpg',
//             'https://assets-prd.ignimgs.com/2022/09/15/artwork-01-1663228486816.jpg'
//         ],
//         created_at: new Date().toISOString(),
//         brand: {
//           brand_id: 1,
//           name: 'Nintendo',
//           image: '',
//           large_image: nintendoLargeLogo
//         }
//     },
//     created_at: new Date().toISOString()
//   };

  export const getCatalog = async (params?: any) => {
    let response:any = await fetch(environment.apiUrl+'/api/catalog/items'+params, {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    });

    response = await response.json();

    return response as ListResponse<Item>;
}

export const getCatalogItem = async (catalog_item_id: number) => {
  let response:any = await fetch(environment.apiUrl+'/api/catalog/items/'+catalog_item_id+'?show_extra_info=true', {
      method: 'GET',
      headers: {
          'Content-type': 'application/json'
      },
  });

  response = await response.json();

  return response as Item;
}

export const accessCatalogItem = async (catalog_item_id: number) => {
  await fetch(environment.apiUrl+'/api/catalog/items/'+catalog_item_id+'/access', {
      method: 'GET',
      headers: {
          'Content-type': 'application/json'
      },
  });
}

export const favoriteCatalogItem = async (catalog_item_id: number) => {
  await fetch(environment.apiUrl+'/api/catalog/items/'+catalog_item_id+'/favorite', {
      method: 'POST',
      headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('auth_token')
      },
  });
}

export const unfavoriteCatalogItem = async (catalog_item_id: number) => {
  await fetch(environment.apiUrl+'/api/catalog/items/'+catalog_item_id+'/favorite', {
      method: 'DELETE',
      headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('auth_token')
      },
  });
}

export const getFavoriteCatalogItem = async (catalog_item_id: number) => {
  let response:any = await fetch(environment.apiUrl+'/api/catalog/items/'+catalog_item_id+'/favorite', {
      method: 'GET',
      headers: {
          'Content-type': 'application/json',
          'Authorization': 'Bearer '+localStorage.getItem('auth_token')
      },
  });

  response = await response.json();

  return response as any;
}