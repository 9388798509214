import giftCards from '../../assets/images/gift-cards.png'
import rightIcon2 from '../../assets/icons/arrow-right-2.png'
import { Link } from 'react-router-dom'

function GiftCards() {
  return (
    <div className="gift-cards">
      <div className='content row'>
        <img src={giftCards} />
        <div className='text'>
          <h3>Gift cards</h3>
          <h2>Gift cards das maiores plataformas</h2>
          <p>Tenha acesso a ofertas de GIft Cards de grandes plataformas que cabem no seu bolso.</p>
          <ul>
            <li>Produtos selecionados.</li>
            <li>Lojas oficiais.</li>
            <li>Ative na sua conta.</li>
          </ul>
          <Link to='catalog?=type=giftcard'>
            <button>
              Saiba mais
              <img src={rightIcon2} />
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default GiftCards