import { useEffect, useRef, useState } from 'react';

function ItemBottomSlider({ slides, currentSlide, onJumpSlide }: { slides: string[], currentSlide?: number, onJumpSlide: (index: number) => any }) {
  const sliderContainer = useRef(null)
  const [currentPosition, toggleCurrentPostion] = useState(0);
  const [currentID, toggleCurrentID] = useState(0);
  const [disable, toggleDisable] = useState(false);
  const initalSlider = useRef<any[]>([])
  const slideWidth = 110;
  const left = slideWidth + 20;
  const [slider, updateSlider] = useState<any[]>([])

  useEffect(() => {
    if(initalSlider.current.length > 0 || slides.length === 0) return;

    initalSlider.current = slides.map((slide, index) => {
        return {
            slide,
            slideIndex: index+1,
            scale: 1,
            left : 0
        }
    });

    const slider = initalSlider.current as any as any[];

    updateSlider([
      ...slider,
      ...slider,
      ...slider,
      ...slider
    ].map((x, index) => ({ ...x, id: index, width: slideWidth, left: (left * (index)) })));

    toggleCurrentID((slider.length * 2));

    const sliderElement = (sliderContainer.current as any as HTMLElement).querySelector('.slider') as HTMLElement;
    sliderElement.style.transform = `translateX(-${(left * ((slider.length * 2))) + (left / 2)}px)`;

  }, [slides])

  useEffect(() => {
    if(slider.length === 0) return;

    jumpSlide((currentSlide as number) - slider.filter(x => x.id === currentID)[0].slideIndex);
  }, [currentSlide, slides])
  

  const jumpSlide = (number: any) => {
    if (disable) return;
    toggleDisable(true);

    let copy: any[] = JSON.parse(JSON.stringify(slider)) as any[];
    let ordered_copy = (JSON.parse(JSON.stringify(slider)) as any[]).sort((a, b) => a.left - b.left);

    ordered_copy.map((x, ordered_index) => {
      let slide_index = copy.indexOf(copy.filter(y => y.id === x.id)[0]);
      let next_step = 0;
      copy[slide_index].zIndex = '-100';
    
      if(number > 0 && ordered_index < number) {
        next_step = (copy[slide_index].left - (left * number)) + (ordered_copy[ordered_copy.length-1].left + left);
  
      } else if(number < 0 && ordered_index > ordered_copy.length-1 + number) {
        next_step = (copy[slide_index].left - (left * number)) - (ordered_copy[ordered_copy.length-1].left + left);

      } else {
        copy[slide_index].zIndex = '1';
        next_step = copy[slide_index].left - (left * number);
      }

      copy[slide_index].left = next_step;
      copy[slide_index].transition = '0.5s';
    });

    updateSlider(copy);
    const currentSlide = copy.filter(x => x.left === left * (initalSlider.current.length * 2))[0];

    toggleCurrentID(currentSlide.id);
    toggleCurrentPostion(currentPosition + number);
    setTimeout(() => {
      toggleDisable(false);
    }, 600);
  }

  const selectSlide = (slide: any) => {
    jumpSlide(slide.id - currentID);
    onJumpSlide(slide.slideIndex);
  }

  return (
    <div ref={sliderContainer} className='bottom-slider-container noselect'>
    <div className='degradee degradee-left'></div>
    <div className='degradee degradee-right'></div>
    <div className='slider'>
        {
        slider.map((slide, i) => 
            <div id={slide.id} key={'slider_'+i} className='slide' style={{'width': slide.width, 'left': slide.left+'px', 'zIndex': slide.zIndex+'', 'scale': currentID === slide.id ? '1.2' : '1', 'transition': slide.transition, 'transformOrigin': slide.transformOrigin}}>
              <img src={slide.slide} />
            </div>
        )
        }
    </div>
    </div>
  );
}

export default ItemBottomSlider;
