import { Item } from '../../infrastructure/services/catalog-item'
import { Link } from 'react-router-dom'

function OtherProducts({ title, catalog_items }: { title: string, catalog_items: Item[] }) {
    const BRReal = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

    return (
        <div className='other-items'>
            <h2 className='title'>{title}</h2>
            <div className='items'>
                {
                    catalog_items.map((item, index) =>
                    <Link key={'catalog_item_'+index} to={'/catalog/'+item.catalog_item_id}> 
                        <div className='item'>
                            <div className="item-img">
                                <img className='img' src={item.image} />
                            </div>
                            <div className='info row'>
                                <div className='item-info'>
                                <h3 className="cut-text">{item.name}</h3>
                                <p>{item.store?.name}</p>
                                </div>
                                <div className='prince-info'>
                                <div className='price-row row'>
                                    {item.discount_price && <p className='discount'>{BRReal.format(item.discount_price)}</p>}
                                </div>
                                {item.discount_percentage &&
                                <div className='discount-percentage row'>
                                    <b>{item.discount_percentage}%</b>
                                    <span>Desconto</span>
                                </div>}
                                </div>
                            </div>
                        </div>
                    </Link>)
                }
            </div>
        </div>
    )
}

export default OtherProducts