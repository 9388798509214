import { Link } from 'react-router-dom'
import './style.sass'
import JBIndicaLogo from '../../assets/icons/JBIndicaLogo.png'
import logo from '../../assets/icons/logo-black-and-white.png'
import linkIcon from '../../assets/icons/link-arrow.png'
import facebookIcon from '../../assets/icons/facebook.png'
import telegramIcon from '../../assets/icons/telegram.png'
import whatsappIcon from '../../assets/icons/whatsapp.png'
import xIcon from '../../assets/icons/x.png' 

function Footer() {
  const jogoBaratoLinks = [
    {
      icon: whatsappIcon,
      name: 'Jogo Barato',
      description: 'Grupo no Whatsapp',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    },
    {
      icon: telegramIcon,
      name: 'Jogo Barato',
      description: 'Grupo no Telegram',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    },
    {
      icon: xIcon,
      name: 'Twitter / X',
      description: '',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    },
    {
      icon: facebookIcon,
      name: 'Facebook',
      description: '',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    }
  ]
  
  const JBIndicaLinks = [
    {
      icon: whatsappIcon,
      name: 'Jogo Barato',
      description: 'Grupo no Whatsapp',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    },
    {
      icon: telegramIcon,
      name: 'Jogo Barato',
      description: 'Grupo no Telegram',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    },
    {
      icon: xIcon,
      name: 'Twitter / X',
      description: '',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    },
    {
      icon: facebookIcon,
      name: 'Facebook',
      description: '',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    }
  ]

  return (
    <div className='footer'>
      <div className='intro-text'>
        <h3>Nossas redes</h3>
        <h2>Nos acompanhe<br/> nas <span>redes sociais</span></h2>
        <p>Você pode acompanhar nossas oferta através dos nossos grupos.</p>
      </div>
      <div className='link-blocks-containers row'>
        <div className='link-blocks-container'>
          <img src={logo} className='logo' />
          <div className='link-blocks'>
            {
              jogoBaratoLinks.map((x, i) => 
                <Link key={'jogo_barato_link_'+i} to={x.link} target='_blank'>
                  <div className='link-block'>
                    <div className='row'>
                      <img src={x.icon} className='icon' />
                      <img src={linkIcon} className='arrow' />
                    </div>
                    <div>
                      <h4>{x.name}</h4>
                      {x.description && <p>{x.description}</p>}
                    </div>
                  </div>
                </Link>
              )
            }
          </div>
        </div>
        <div className='link-blocks-container'>
          <img src={JBIndicaLogo} className='logo' />
          <div className='link-blocks'>
            {
              JBIndicaLinks.map((x, i) => 
              <Link key={'jb_indica_link_'+i} to={x.link} target='_blank'>
                <div className='link-block'>
                  <div className='row'>
                    <img src={x.icon} className='icon' />
                    <img src={linkIcon} className='arrow' />
                  </div>
                  <div>
                    <h4>{x.name}</h4>
                    {x.description && <p>{x.description}</p>}
                  </div>
                </div>
              </Link>
              )
            }
          </div>
        </div>
      </div>
      <div className='group'>
        <p>Grupo:</p>
        <img src={logo} className='logo' />
      </div>
      <div className='bottom-links row'>
        <Link to='privacy-politics'>Políticas de Privacidade</Link>
        <Link to='terms-and-conditions'>Termos e condições</Link>
        <Link to='contact'>Contato</Link>
      </div>
    </div>
  )
}

export default Footer