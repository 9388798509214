import { Product } from '../../infrastructure/services/catalog-item'

function ItemProducts({ title, products }: { title: string, products: {product: Product}[] }) {
    return (
        <div className='other-items'>
            <h2 className='title'>{title}</h2>
            <div className='items'>
                {
                    products.map((item, index) =>
                    <div key={'product_'+index} className='item'>
                        <div className="item-img">
                            <img className='img' src={item.product.image} />
                        </div>
                        <div className='info row'>
                            <div className='item-info'>
                            <h3 className="cut-text">{item.product.name}</h3>
                            </div>
                        </div>
                    </div>)
                }
            </div>
        </div>
    )
}

export default ItemProducts