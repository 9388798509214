import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Item, ListResponse, getCatalog } from '../../infrastructure/services/catalog-item'
import searchIcon from '../../assets/icons/search.png'
import rightIcon2 from '../../assets/icons/arrow-right-2.png'
import JBIndicaLogo from '../../assets/icons/JBIndicaLogo.png'
import logo from '../../assets/icons/logo-black-and-white.png'
import linkIcon2 from '../../assets/icons/link-arrow-2.png'
import facebookIcon from '../../assets/icons/facebook.png'
import telegramIcon from '../../assets/icons/telegram.png'
import whatsappIcon from '../../assets/icons/whatsapp.png'
import xIcon from '../../assets/icons/x.png'
import './style.sass'
import Select from '../../components/select/Select'
import { getStores } from '../../infrastructure/services/store'
import { getBrands } from '../../infrastructure/services/brand'
import { getCategories } from '../../infrastructure/services/category'

function Catalog() {
  const location = useLocation()
  const navigator = useNavigate()
  const BRReal = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
  const [categoryOptions, setCategoryOptions] = useState<any[]>([])
  const [storeOptions, setStoreOptions] = useState<any[]>([])
  const [brandOptions, setBrandOptions] = useState<any[]>([])
  const [results, updateResults] = useState<ListResponse<Item>>({
    data: [],
    count: 0
  })
  const [loading, toggleLoading] = useState<boolean>(false)
  const [searchParams, updateSearchParams] = useState<Record<string, any>>({
    offset: 0,
    limit: 10
  })
  const jogoBaratoLinks = [
    {
      icon: whatsappIcon,
      name: 'Jogo Barato',
      description: 'Grupo no Whatsapp',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    },
    {
      icon: telegramIcon,
      name: 'Jogo Barato',
      description: 'Grupo no Telegram',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    },
    {
      icon: xIcon,
      name: 'Twitter / X',
      description: '',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    },
    {
      icon: facebookIcon,
      name: 'Facebook',
      description: '',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    }
  ]
  
  const JBIndicaLinks = [
    {
      icon: whatsappIcon,
      name: 'Jogo Barato',
      description: 'Grupo no Whatsapp',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    },
    {
      icon: telegramIcon,
      name: 'Jogo Barato',
      description: 'Grupo no Telegram',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    },
    {
      icon: xIcon,
      name: 'Twitter / X',
      description: '',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    },
    {
      icon: facebookIcon,
      name: 'Facebook',
      description: '',
      link: 'https://www.youtube.com/watch?v=KLRRmTpBH5I'
    }
  ]

  useEffect(() => {
    toggleLoading(true);
    getCategoryOptions();
    getStoreOptions();
    getBrandOptions();
  }, [])

  const getCategoryOptions = async () => {
    const options = (await getCategories()).map(x => ({ value: x.category_id, label: x.name }));

    setCategoryOptions(options);
  }

  const getStoreOptions = async () => {
    const options = (await getStores()).map(x => ({ value: x.store_id, label: x.name }));

    setStoreOptions(options);
  }

  const getBrandOptions = async () => {
    const options = (await getBrands()).map(x => ({ value: x.brand_id, label: x.name }));

    setBrandOptions(options);
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    let new_params: Record<string, any> = {};
    Array.from(params.keys()).map(key => {
      new_params[key] = params.get(key);
    });
    updateSearchParams(new_params);

    listCatalogItems(new_params); 
  }, [location])

  const onUpdateParams = (param: string, value: any) => {
    updateSearchParams(prev => ({...prev, [param]: value}));
  }

  const listCatalogItems = async (searchParams: Record<string, any>) => {
    try {
      toggleLoading(true);

      let search = '';
      const params = Object.keys(searchParams).filter(key => ![undefined, null, ''].includes(searchParams[key]));

      if(params.length > 0) {
        search += '?'
      };
  
      params.map((key, index) => {
        search += key+'='+searchParams[key];
        if (index < params.length -1) {
          search += '&';
        };
      });

      const results = await getCatalog(search);

      updateResults(results);
    } catch (error) {
      
    };
    setTimeout(() => {
      toggleLoading(false);
    }, 500);
  }

  const URLSearchParamsGenerator = (extraParams?: Record<string, any>) => {
    let search = '';
    
    const allParamsObj = {...searchParams, ...extraParams};
    const params = Object.keys(allParamsObj).filter(key => ![undefined, null, ''].includes(allParamsObj[key]));

    if(params.length > 0) {
      search += '?'
    };

    params.map((key, index) => {
      search += key+'='+allParamsObj[key];
      if (index < params.length -1) {
        search += '&';
      };
    });

    return '/catalog'+search;
  }

  const expanItemExtraInfo = (itemId: string) => {
    const item = document.getElementById(itemId) as any;
    if(!item.querySelector('.extra-info')) return;

    item.querySelector('.extra-info-container').style.height = item.querySelector('.extra-info').offsetHeight + 'px';
  }

  const closeItemExtraInfo = (itemId: string) => {
    const item = document.getElementById(itemId) as any;
    if(!item.querySelector('.extra-info')) return;

    item.querySelector('.extra-info-container').style.height = '0px';
  }

  return (
    <div className='catalog page'>
      <div className='content'>
        <div className='catalog-header'>
          <div className='first-block'>
            <h2>Melhores resultados</h2>
            <p>A combinação perfeita entre preço e qualidade em jogos e eletrônicos. Nessa página você pode visualizar os melhores negócios para você. Se você quer variedade de opções e os melhores preços, você veio ao lugar certo.</p>
            {/* <div className='general-filters'>
              <label>Filtro:</label>
              <div className='row'>
                <button className={searchParams['filter'] === 'all' ? 'selected' : ''} onClick={() => onUpdateParams('filter', 'all')}>Todos</button>
                <button className={searchParams['filter'] === 'offers' ? 'selected' : ''} onClick={() => onUpdateParams('filter', 'offers')}>Ofertas</button>
                <button className={searchParams['filter'] === 'highlights' ? 'selected' : ''} onClick={() => onUpdateParams('filter', 'highlights')}>Destaques</button>
                <button className={searchParams['filter'] === 'news' ? 'selected' : ''} onClick={() => onUpdateParams('filter', 'news')}>Novidades</button>
              </div>
            </div> */}
          </div>
          <div className='second-block search-inputs row'>
            <div className='search-input'>
              <input type='text' placeholder='Pesquisar...' value={searchParams['search']} onChange={e => onUpdateParams('search', e.target.value)} />
              <img src={searchIcon} className='search-icon' onClick={() => navigator(URLSearchParamsGenerator({ offset: 0 }))} />
            </div>
            <div className='filters row'>
              <Select
              placeholder='Categoria'
              options={categoryOptions}
              selectedOption={searchParams['category_id']}
              onSelect={(value: any) => onUpdateParams('category_id', value)} />
              <Select
              placeholder='Loja'
              options={storeOptions}
              selectedOption={searchParams['store_id']}
              onSelect={(value: any) => onUpdateParams('store_id', value)} />
              <Select
              placeholder='Marca'
              options={brandOptions}
              selectedOption={searchParams['brand_id']}
              onSelect={(value: any) => onUpdateParams('brand_id', value)} />
              <Select
              placeholder='Ordenar por'
              options={[
                {
                  value: 'hightlights',
                  label: 'Destaques'
                },
                {
                  value: 'news',
                  label: 'Novidades'
                },
                {
                  value: 'offers',
                  label: 'Ofertas'
                }
              ]}
              selectedOption={searchParams['order_by']}
              onSelect={(value: any) => onUpdateParams('order_by', value)} />
              <Select
              placeholder='Ordem'
              options={[
                {
                  value: 'asc',
                  label: 'Mais antigo'
                },
                {
                  value: 'desc',
                  label: 'Mais recente'
                }
              ]}
              selectedOption={searchParams['order_sort']}
              onSelect={(value: any) => onUpdateParams('order_sort', value)} />
            </div>
          </div>
        </div>
        <div className='catalog-content row'>
          {
            loading ?
            <div>
              <p>Carregando...</p>
            </div> : 
            <>
              <div className='results'>
                {
                  results.count > 0 ?
                  results.data.map((item, index) =>
                    <Link key={'catalog_item_'+index} to={'/catalog/'+item.catalog_item_id}>
                      <div id={'catalog_item_'+index} className='item row' onMouseOver={() => expanItemExtraInfo('catalog_item_'+index)} onMouseLeave={() => closeItemExtraInfo('catalog_item_'+index)}>
                        <div className="product-img">
                          <img className='store-logo' src={item.store?.image} />
                          <img className='img' src={item.image} />
                        </div>
                        <div className="info">
                          <div className='main-info row'>
                            <div className='product-info'>
                              <h3 className="cut-text">{item.name}</h3>
                              <p>{item.store?.name}</p>
                            </div>
                            <div className='prince-info'>
                              <div className='price-row row'>
                                {item.discount_price && <p className='discount'>{BRReal.format(item.discount_price)}</p>}
                              </div>
                              {item.discount_percentage &&
                              <div className='discount-percentage row'>
                                <b>{item.discount_percentage}%</b>
                                <span>Desconto</span>
                              </div>}
                            </div>
                          </div>
                          {
                            item.extra_info &&
                            <div className='extra-info-container'>
                              <div className='extra-info' dangerouslySetInnerHTML={{__html: item.extra_info}}></div>
                            </div>
                          }
                        </div>
                        <div className='access-cta'>
                          <img src={rightIcon2} />
                        </div>
                      </div>
                    </Link>  
                  ) :
                  <div>
                    <p>Nenhum resultado encontrado</p>
                  </div>
                }
                {
                  results.count > 10 &&
                  <div className='paginator'>
                    <Link to={'catalog'}><span>{'<<'}</span></Link>
                    {Array.from(Array(results.count).keys()).map(i => <Link key={'paginator_'+i} to={URLSearchParamsGenerator({ offset: i+1 })}><span>{i+1}</span></Link>)}
                    <Link to={URLSearchParamsGenerator({ offset: Math.floor(results.count / 10) })}><span>{'>>'}</span></Link>
                  </div>
                }
              </div>
              <div className='sidebar'>
                <div className='link-blocks-containers'>
                  <div className='link-blocks-container'>
                    <img src={logo} className='logo' />
                    <div className='link-blocks'>
                      {
                        jogoBaratoLinks.map((x, i) => 
                          <Link key={'jogo_barato_link_'+i} to={x.link} target='_blank'>
                            <div className='link-block'>
                              <div className='row'>
                                <img src={x.icon} className='icon' />
                                <img src={linkIcon2} className='arrow' />
                              </div>
                              <div>
                                <h4>{x.name}</h4>
                                {x.description && <p>{x.description}</p>}
                              </div>
                            </div>
                          </Link>
                        )
                      }
                    </div>
                  </div>
                  <div className='link-blocks-container'>
                    <img src={JBIndicaLogo} className='logo' />
                    <div className='link-blocks'>
                      {
                        JBIndicaLinks.map((x, i) => 
                        <Link key={'jb_indica_link_'+i} to={x.link} target='_blank'>
                          <div className='link-block'>
                            <div className='row'>
                              <img src={x.icon} className='icon' />
                              <img src={linkIcon2} className='arrow' />
                            </div>
                            <div>
                              <h4>{x.name}</h4>
                              {x.description && <p>{x.description}</p>}
                            </div>
                          </div>
                        </Link>
                        )
                      }
                    </div>
                  </div>
                </div>
              <div className='googleads'></div>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default Catalog