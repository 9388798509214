import { environment } from "../../environments/environment";
import { Item } from "./catalog-item";

export interface Slide {
    slide_id: number,
    catalog_items: Item[]
};

export const getSlides = async () => {
    let response:any = await fetch(environment.apiUrl+'/api/slides?with_detailed_slides=true', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        },
    });

    response = await response.json();

    return response.data as Slide[];
}