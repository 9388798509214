import { environment } from "../../environments/environment";

export interface LoginData {
    email: string,
    password: string
}

export interface LoginResponse {
    auth_token: string
}

export interface User {
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    usertype: string,
    password: string,
    password_confirmation?: string
}

export type UserKey = keyof User

export type LoginKey = keyof LoginData

export const onLogin = async (data: LoginData) => {
    try {
        const response = await fetch(environment.apiUrl+'/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if(response.status === 400) {
            throw Error();
        };

        const response_data = await response.json() as LoginResponse;

        return response_data;
    } catch (error) {
        alert('Login inválido');
    }
}

export const onSignIn = async (data: LoginData) => {
    try {
        let response = await fetch(environment.apiUrl+'/api/auth/signup', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        if(response.status === 400) {
            throw Error();
        };

        response = await response.json();

        return response;
    } catch (error) {
        alert('Usuário já existe');
    }
}

export const getUserData = async (user_id: string) => {
    try {
        const response = await fetch(environment.apiUrl+'/api/users/'+user_id, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer '+localStorage.getItem('auth_token')
            },
        });

        if(response.status === 400) {
            throw Error();
        };

        const user_data = await response.json() as User;

        return user_data;
    } catch (error) {
        localStorage.clear();
    }
}