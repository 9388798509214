import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import './style.sass'
import './ckeditor5.css'
import { Item, accessCatalogItem, favoriteCatalogItem, getCatalogItem, getFavoriteCatalogItem, unfavoriteCatalogItem } from '../../infrastructure/services/catalog-item'
import rightIcon2 from '../../assets/icons/arrow-right-2.png'
import copyIcon from '../../assets/icons/copy.png'
import warningIcon from '../../assets/icons/warning.png'
import loveIcon from '../../assets/icons/love.png'
import loveCheckedIcon from '../../assets/icons/love-checked.svg'
import editIcon from '../../assets/icons/edit.svg'
import ItemSlider from './ItemSlider'
import OtherProducts from './OtherProducts'
import { getUserData } from '../../infrastructure/services/user'
import { environment } from '../../environments/environment'
import ItemProducts from './ItemProducts'

function CatalogItem() {
  const navigate = useNavigate()
  const params = useParams()
  const BRReal = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
  const [catalogItem, setCatalogItem] = useState<Item | undefined>()
  const [itemFavorited, toggleItemFavorited] = useState(false)
  const [userType, setUserType] = useState<string | undefined>()
  const userLoggedId = localStorage.getItem('auth_token') && localStorage.getItem('user_id');

  useEffect(() => {
    params.item_id && accessCatalogItem(parseInt(params.item_id));
    onGetCatalogItem();
    getUserType();
  }, [])

  const getUserType = async () => {
    if(userLoggedId) {
      const userData = await getUserData(userLoggedId);
      if(userData) {
        setUserType(userData.usertype);
      };
    };
  }
  
  const onGetCatalogItem = async () => {
    if(!params.item_id) {
      navigate('/catalog');

      return;
    };

    const item = await getCatalogItem(parseInt(params.item_id as string));

    setCatalogItem(item);

    onGetFavoriteCatalogItem(item.catalog_item_id); 
  }

  const onGetFavoriteCatalogItem = async (catalog_item_id: number) => {
    if(!userLoggedId) return;

    const response = await getFavoriteCatalogItem(catalog_item_id);

    toggleItemFavorited(response.response);
  }

  const onToggleFavoriteItem = () => {
    !itemFavorited ? favoriteCatalogItem(catalogItem?.catalog_item_id as number) : unfavoriteCatalogItem(catalogItem?.catalog_item_id as number);

    toggleItemFavorited(!itemFavorited);
  }

  const copyCouponCode = (coupon: string) => {
    try {
      navigator.clipboard.writeText(coupon);
      alert('Cupom copiado!');
    } catch (error) {};
  }

  const catalogItemImages = () => {
    let images = catalogItem?.products.map(x => [x.product.image, ...x.product.extra_images]).flat();
    console.log(images)
    if(images?.length === 0) {
      images = catalogItem?.image ? [catalogItem?.image] : [];
    };

    console.log(images)
    return images as any[];
  }

  return (
    <div className='catalog-item page'>
      {catalogItem && 
      <div className='content row'>
        <div className='item-showcase'>
          <div className='name-row row'>
            <h2>{catalogItem.name}</h2>
            {userLoggedId &&
            <div className='icons'>
              {userLoggedId &&
              <img src={!itemFavorited ? loveIcon : loveCheckedIcon} className={!itemFavorited ? 'love-icon' : 'love-icon checked'} onClick={onToggleFavoriteItem} />}
              {userType === 'admin' && <a href={`${environment.backofficeUrl}/catalog/items/${catalogItem.catalog_item_id}`} target='_blank'><img src={editIcon} className='edit-icon' /></a>}
            </div>}
          </div>
          <ItemSlider passedSlides={catalogItemImages()} />
          <div className='item-selling-info'>
            <img className='store-logo' src={catalogItem.store?.image} />
            {/* <img className='brand-logo' src={catalogItem.product?.brand?.large_image} /> */}
          </div>
          <ItemProducts title='Produtos' products={catalogItem.products ? catalogItem.products : []} />
          <OtherProducts title='Promoções relacionadas' catalog_items={catalogItem.related_items ? catalogItem.related_items : []} />
          <OtherProducts title='Quem viu este produto também comprou' catalog_items={catalogItem.users_other_favorites ? catalogItem.users_other_favorites : []} />
        </div>
        <div className='catalog-item-info'>
          {/* <div className='price-row row'>
            {catalogItem.discount_price && <h3>{BRReal.format(catalogItem.discount_price)}</h3>}
            <div className='ranking'></div>
          </div> */}
          <div className='row coupon-row'>
            <div className='links'>
              <h3>Links</h3>
              {
                catalogItem.links.map((link, index) =>
                <Link to={link.link+''} target='_blank'>
                  <button>
                    {link.text}
                    <img src={rightIcon2} />
                  </button>
                </Link>)
              }
            </div>
          </div>
          <div className='row coupon-row'>
            <div className='links'>
              <h3>Links de Cupons</h3>
              {
                catalogItem.coupon_links.map((link, index) =>
                <Link to={link.link+''} target='_blank'>
                  <button>
                    {link.text}
                    <img src={rightIcon2} />
                  </button>
                </Link>)
              }
            </div>
          </div>
          <div className='row coupon-row'>
            <div className='coupons'>
              <h3>Cupons</h3>
              {
                catalogItem.coupons.map((coupon, index) =>
                <div key={'coupon_'+index} className='coupon' onClick={() => copyCouponCode(coupon)}>
                  <span>{coupon}</span>
                  <img className='copy-icon' src={copyIcon}/>
                </div>)
              }
            </div>
          </div>
          {catalogItem.warning &&
          <div className='coupon-warning-row row'>
            <img src={warningIcon} />
            <p>{catalogItem.warning}</p>
            {/* <p>Ative {catalogItem.coupons.length === 1 ? 'o cupom' : 'os cupons'} {catalogItem.discount_percentage ? 'de '+catalogItem.discount_percentage+'%' : ''} na página do produto!</p> */}
          </div>}
          <div className='description'>
            <h4>{catalogItem.name}</h4>
            <div className='ck-content' dangerouslySetInnerHTML={{__html: catalogItem.description}}></div>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default CatalogItem