import { Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import Catalog from './pages/catalog/Catalog';
import CatalogItem from './pages/catalog-item/CatalogItem';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import NotFound from './pages/not-found/not-found';
import PrivacyPolitics from './pages/privacy-politics/privacy-politics';
import TermsAndConditions from './pages/terms-and-conditions/terms-and-conditions';
import Contact from './pages/contact/contact';
import Login from './pages/login/login';
import SignUp from './pages/sign-up/sign-up';

function App() {
  return (
    <div className="main">
      <Header/>
      <Routes>
        <Route element={<Home/>} path="/" />
        <Route element={<Catalog/>} path="/catalog" />
        <Route element={<CatalogItem/>} path="/catalog/:item_id" />
        <Route element={<PrivacyPolitics/>} path='privacy-politics' />
        <Route element={<TermsAndConditions/>} path='terms-and-conditions' />
        <Route element={<Contact/>} path='contact' />
        <Route element={<Login/>} path='login' />
        <Route element={<SignUp/>} path='sign-up' />
        <Route element={<NotFound/>} path="*" />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
