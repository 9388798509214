import { useEffect, useRef, useState } from "react"
import './style.sass'

function Select({ placeholder, options, selectedOption, onSelect }: { placeholder?: string, options: { value: any, label: string }[], selectedOption?: any, onSelect: Function }) {
    const element = useRef(null)
    const[showOptions, toggleOptions] = useState(false)
    const [currentSelectedOption, setCurrentSelectedOption] = useState<any>()

    useEffect(() => {
        document.addEventListener('click', e => {
            if(element.current && !(element.current as HTMLElement).contains(e.target as HTMLElement)) {
                toggleOptions(false);
            };
        });
    }, [])

    useEffect(() => {
      setCurrentSelectedOption(selectedOption);
    }, [selectedOption])
    

    const getSelectedOption = () => {
        const filterResult = options.filter(x => x.value+'' === currentSelectedOption+'')[0];

        return filterResult ? filterResult.label : '';
    }

    const onOptionSelected = (value: any) => {
        onSelect(value);
    }

    return (
        <div ref={element} className={'select '+(showOptions ? 'opened' : '')}>
            <input
            type="text"
            placeholder={placeholder}
            readOnly
            value={getSelectedOption()}
            onClick={() => toggleOptions(!showOptions)} />
           {showOptions &&
           <div className="options">
                <div className="option" onClick={() => {onOptionSelected(''); toggleOptions(false)}}>-</div>
                {
                    options.map(option => 
                        <div 
                        className={'option '+ (selectedOption === option.value ? 'selected' : '')}
                        onClick={() => {onOptionSelected(option.value); toggleOptions(false)}}>
                            {option.label}
                        </div>
                    )
                }
            </div>}
        </div>
    )
}

export default Select