import { useEffect, useRef, useState } from 'react'
import rightIcon2 from '../../assets/icons/arrow-right-2.png'
import { Link } from 'react-router-dom'
import { Slide, getSlides } from '../../infrastructure/services/slide'

function Slider() {
  const sliderRef = useRef(null)
  const [initialSlides, updateInitialSlides] = useState<Slide[]>([])
  const [slides, updateSlides] = useState<Slide[]>([])
  const [currentSlide, changeCurrentSlide] = useState(1)
  const [slidePosition, updateSlidePosition] = useState(0)
  const [sliderInterval, updateSliderInterval] = useState<any>()
  const [positionCalcResult, updatePositionCalcResult] = useState<number>(0)
  const currentSlideRef = useRef(1)
  const slidePositionRef = useRef(1)

  useEffect(() => {
    onGetSlides();
  }, [])

  const onGetSlides = async () => {
    const slides = await getSlides();

    if(slides.length === 0) return;

    const slidesCopy = slides.length > 1 ? [...slides] : [...slides, ...slides];
    const firstSlide = slidesCopy.shift() as Slide;
    const lastSlide = slidesCopy.pop() as Slide;

    updateInitialSlides(slides);

    updateSlides([
      lastSlide,
      ...slides,
      firstSlide
    ]);

    setPositionCalcResult();
    window.addEventListener('resize', () => setPositionCalcResult());
  }

  useEffect(() => {
    currentSlideRef.current = currentSlide;
    slidePositionRef.current = slidePosition;
  }, [currentSlide, slidePositionRef])
  
  useEffect(() => {
    if(slides.length > 0) return;

    updateSlidePosition(window.innerWidth);
  }, [slides])

  const setPositionCalcResult = () => {
    if(window.innerWidth <= 1500) return;

    const calc = (window.innerWidth - 1500) / 2;

    updatePositionCalcResult(calc);
  }
  
  const onBtnClick = (new_slide_position: number) => {
    clearInterval(sliderInterval);
    updateSliderInterval(null);
    changeSlide(new_slide_position);

    // setTimeout(() => {
    //   updateSliderInterval(setInterval(() => {
    //     changeSlide(currentSlideRef.current + 1);
    //   }, 5000))
    // }, 500);
  };

  const changeSlide = (new_slide_position: number) => {
    if(!sliderRef.current) return;
    if(new_slide_position == currentSlideRef.current) return;
    const slider = document.querySelector('.slides') as HTMLElement;
    const sliderContainer = sliderRef.current as HTMLElement;

    if(new_slide_position > currentSlideRef.current) {
      if(new_slide_position == slides.length-1) {
        slider.style.transition = '0.5s';
        sliderContainer.style.pointerEvents = 'none';
        updateSlidePosition(window.innerWidth * (new_slide_position));
        changeCurrentSlide(1);

        setTimeout(() => {
          slider.style.transition = '0s';
          sliderContainer.style.pointerEvents = 'inherit';
          updateSlidePosition(window.innerWidth);
        }, 500);
        
        return
      };

      slider.style.transition = '0.5s';
      sliderContainer.style.pointerEvents = 'none';
      updateSlidePosition(window.innerWidth * (new_slide_position));
      changeCurrentSlide(new_slide_position);

      setTimeout(() => {
        sliderContainer.style.pointerEvents = 'inherit';
      }, 500);
    };

    if(new_slide_position < currentSlide) {
      if(new_slide_position == 0) {
        slider.style.transition = '0.5s';
        sliderContainer.style.pointerEvents = 'none';
        updateSlidePosition(0);
        changeCurrentSlide(initialSlides.length);

        setTimeout(() => {
          slider.style.transition = '0s';
          sliderContainer.style.pointerEvents = 'inherit';
          updateSlidePosition(window.innerWidth * (initialSlides.length));
        }, 500);
        
        return
      };

      slider.style.transition = '0.5s';
      sliderContainer.style.pointerEvents = 'none';
      updateSlidePosition(window.innerWidth * (new_slide_position));
      changeCurrentSlide(new_slide_position);

      setTimeout(() => {
        sliderContainer.style.pointerEvents = 'inherit';
      }, 500);
    };
  }

  return (
    <div ref={sliderRef} className='slider-container noselect'>
      {/* <button className='previous jump-btn' onClick={() => onBtnClick(currentSlide-1)}>
        <img src={leftIcon} />
      </button>
      <button className='next jump-btn' onClick={() => onBtnClick(currentSlide+1)}>
        <img src={rightIcon} />
      </button> */}
      <div className='slides' style={{marginLeft: -slidePosition+'px'}}>
        {
          slides && slides.map((slide, index) => 
            <div key={'slide_'+index} className='slide' style={{width: window.innerWidth}}>
              {/* <img className='company-logo' style={{ 'left': positionCalcResult+'px' }} src={slide.catalog_items[0].brand?.image} /> */}
              <div className='left-column product-block'>
                <div className='slide-info' style={{ 'left': positionCalcResult+'px' }}>
                  <h1>{slide.catalog_items[0].name}</h1>
                  {/* <p className='details'>{slide.catalog_items[0].}</p> */}
                  {/* <Link to={slide.catalog_items[0].link}>
                    <button>
                      Ir para loja
                      <img src={rightIcon2} />
                    </button>
                  </Link> */}
                </div>
                <img className='bg' src={slide.catalog_items[0].image} />
              </div>
              <div className='right-column'>
                <div className='product-block'>
                  {/* <img className='company-logo' style={{ 'right': positionCalcResult+'px' }} src={slide.catalog_items[1].brand?.image} /> */}
                  <div className='slide-info' style={{ 'right': positionCalcResult+'px' }}>
                    <div>
                      <h1>{slide.catalog_items[1].name}</h1>
                      {/* <p className='details'>{slide.details}</p> */}
                    </div>
                    {/* <Link to={slide.catalog_items[1].link}>
                      <button>
                        <img src={rightIcon2} />
                      </button>
                    </Link> */}
                  </div>
                  <img className='bg' src={slide.catalog_items[1].image} />
                </div>
                <div className='product-block'>
                  {/* <img className='company-logo' style={{ 'right': positionCalcResult+'px' }} src={slide.catalog_items[2].brand?.image} /> */}
                  <div className='slide-info' style={{ 'right': positionCalcResult+'px' }}>
                    <div>
                      <h1>{slide.catalog_items[2].name}</h1>
                      {/* <p className='details'>{slide.details}</p> */}
                    </div>
                    {/* <Link to={slide.catalog_items[2].link}>
                      <button>
                        <img src={rightIcon2} />
                      </button>
                    </Link> */}
                  </div>
                  <img className='bg' src={slide.catalog_items[2].image} />
                </div>
              </div>
            </div>  
          )
        }
      </div>
      <div className='slide-dots'>
        {
          Array.from(Array(initialSlides.length).keys()).map(i => 
            <div key={'dot_'+i} className={'dot '+ (currentSlide === i+1 ? 'selected' : '')} onClick={() => onBtnClick(i+1)}></div>
          )
        }
      </div>
    </div> 
  )
}

export default Slider