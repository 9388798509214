import Bars from './Bars'
import GiftCards from './GiftCards'
import Highlights from './Highlights'
import Slider from './Slider'
import './style.sass'

function Home() {
  return (
    <div className='home page'>
      <Slider/>
      <Bars/>
      <Highlights/>
      <GiftCards/>
    </div>
  )
}

export default Home